import { URLS } from '../../constants';
import axios from '../../../Axios';
import {
  GET_DAIRY_LOCATION_BY_CLIENT_ID_GETTER,
  GET_CONTACT_BY_CLIENT_ID_GETTER,
  SET_CLIENT_BY_ID,
  SET_CLIENT_BY_USER_ID,
  GET_CLIENT_BY_USER_ID,
  GET_CLIENT_BY_ID,
  GET_ADD_CLIENT_INTO_DB,
  SET_ADD_CLIENT_INTO_DB,
  SET_CLIENT_IN_EVALUATION,
  SET_UPDATE_CLIENT,
  GET_UPDATE_CLIENT,
  GET_CLIENT_BY_ID_GETTER,
  GET_CLIENTS,
  SET_CLIENTS,
} from '../types';

const state = {
  clients: [{}],
};

//In Component: this.$store.getters.functionName;
const getters = {
  [GET_DAIRY_LOCATION_BY_CLIENT_ID_GETTER]: state => CLIENT_ID => {
    return state.clients.find(CLIENT => CLIENT._id === CLIENT_ID);
  },
  [GET_CONTACT_BY_CLIENT_ID_GETTER]: state => CLIENT_ID => {
    return state.clients.find(CLIENT => CLIENT._id === CLIENT_ID);
  },
  [GET_CLIENT_BY_ID_GETTER]: state => CLIENT_ID => {
    return state.clients.find(CONTACT => CONTACT._id === CLIENT_ID);
  },
};

const mutations = {
  [SET_CLIENT_BY_ID](STATE, CLIENT) {
    STATE.clients.unshift(CLIENT);
  },
  [SET_CLIENT_BY_USER_ID](STATE, CLIENT) {
    STATE.clients.unshift(CLIENT);
  },
  [SET_ADD_CLIENT_INTO_DB](STATE, CLIENT) {
    STATE.clients.unshift(CLIENT);
  },
  [SET_UPDATE_CLIENT](STATE, CLIENT) {
    STATE.clients = STATE.clients.map(client =>
      client.id === CLIENT.id ? CLIENT : client
    );
  },
  [SET_CLIENTS](STATE, CLIENTS) {
    STATE.clients = [...CLIENTS];
  },
};
const actions = {
  [GET_CLIENT_BY_USER_ID]({ commit: COMMIT }, USER_ID) {
    return new Promise(function(resolve, reject) {
      axios
        .get(`${URLS.CLIENT}/${USER_ID}`)
        .then(function(res) {
          COMMIT(SET_CLIENT_BY_USER_ID, res.data.data);
          return resolve(res.data.data);
        })
        .catch(function(err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },

  [GET_CLIENT_BY_ID]({ commit: COMMIT }, CLIENT_ID) {
    return new Promise(function(resolve, reject) {
      axios
        .get(`${URLS.CLIENT}/${CLIENT_ID}`)
        .then(function(res) {
          COMMIT(SET_CLIENT_BY_ID, res.data.data);
          return resolve(res.data.data);
        })
        .catch(function(err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },
  [GET_ADD_CLIENT_INTO_DB]({ commit: COMMIT }, CLIENT) {
    COMMIT(SET_ADD_CLIENT_INTO_DB, CLIENT);
    COMMIT(`evaluations/${SET_CLIENT_IN_EVALUATION}`, CLIENT, {
      root: true,
    });
    return new Promise(function(resolve, reject) {
      axios
        .post(URLS.CLIENT, CLIENT)
        .then(CLIENT_DOC => {
          return resolve(CLIENT_DOC.data.data);
        })
        .catch(err => {
          return reject(err.response ? err.response.data : err);
        });
    });
  },
  [GET_UPDATE_CLIENT]({ commit: COMMIT }, CLIENT) {
    COMMIT(SET_UPDATE_CLIENT, CLIENT);
    COMMIT(`evaluations/${SET_CLIENT_IN_EVALUATION}`, CLIENT, {
      root: true,
    });
    return new Promise(function(resolve, reject) {
      axios
        .put(`${URLS.CLIENT}/${CLIENT._id}`, CLIENT)
        .then(CLIENT_DOC => {
          return resolve(CLIENT_DOC.data.data);
        })
        .catch(err => {
          return reject(err.response ? err.response.data : err);
        });
    });
  },
  [GET_CLIENTS]({ commit: COMMIT }) {
    return new Promise(function(resolve, reject) {
      axios
        .get(URLS.CLIENT)
        .then(CLIENT_DOC => {
          COMMIT(SET_CLIENTS, CLIENT_DOC.data.data);
          return resolve(CLIENT_DOC.data.data);
        })
        .catch(err => {
          return reject(err.response ? err.response.data : err);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
